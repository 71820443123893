<template>
  <div class="map1" id="map1">
    <!-- 底图切换和地图 -->
    <div class="map" id="map">
      <div class="change">
        <div class="changedt" id="changedt" @click="showdt()">卫星图</div>
        <div class="changewx" id="changewx" @click="showwx()">行政图</div>
      </div>
    </div>
    <!-- 天气警报播放栏 -->
    <div class="u-notice-bar" @click="showNotice()">
      <div>
        <img src="./notice.png" style="
          height: 25px;
          width: 20px;
          position: absolute;
          top:3px;
          left: 0px;
          padding-left: 10px;
          z-index: 998;"></img>
      </div>
      <div class="u-notice-bar-content">
        <span class="u-notice-bar-text">{{ notice }}</span>
      </div>
    </div>
    <!-- 点击地图上点后显示的信息 -->
    <div class="showabout" v-if="showAbout">
      <img id="pic" :src="src_true" />
      <div id="name">{{ picname }}</div>
      <div id="distance">距您{{ distance + unit1 }}</div>
      <div id="go">
        <img id="pic" style="z-index: 999" src="https://walkway.ntscn.com:8818/images/goto.png"
          @click="open(latitude, longitude, picname)" />
        <div @click="open(latitude, longitude, picname)">到这里</div>
        <i class="el-icon-arrow-right" @click="open(latitude, longitude, picname)"
          style="z-index: 999;height: 100%;width: 25%;top: 14%; left: 66%; font-size: 22px;position: absolute;"></i>
      </div>
      <i class="el-icon-close" @click="closeAbout"></i>
    </div>
    <!-- 屏幕下方最初显示的div -->
    <div class="showwalk" id="showwalk">
      <!-- 上拉按钮 -->
      <div id="allbutton" @click="drawers()" style="white-space: nowrap;">
        <p id='ChaKan' class="ChaKan">
          上拉查看步道推荐线路</p>
        <img class="showbutton" id="showbutton" src="https://walkway.ntscn.com:8818/images/pull.svg" />
      </div>
      <!-- 显示下方六个数据点的div -->
      <div id="walkdata" style="overflow-x: scroll;">
        <el-row>
          <el-col :span="6" :key="index" v-for="(item, index) in pointType_true">
            <div class="grid-item" @click="showpoint(item.id, item.image, item.description)">
              <img class="pointImgs" :src="item.image" />
              <p style="top: -20px;height: 16px;padding: 1px;position: relative;font-size: 13px;">{{ item.title }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 显示推荐线路的div -->
      <div class="showallwalk" v-if="showallway" style="z-index: 997;height: 52.5%;background-color: rgb(255, 255, 255,0); width: 100vw;
          bottom: 0%; right: 0%;overflow-x: scroll;position: absolute; ">
        <div class="allwalk" :style="{ width: divWidth + 'vw' }" v-if="isHave">
          <div v-for="(item, index) in linelist" :key="index" class="list-item"
            style="overflow-x: auto; margin: 0px; top: 0px; position: relative">
            <div class="title">
              <div class="order2">线路</div>
              <div class="number">{{ index + 1 }}</div>
            </div>
            <div class="content" :style="{ pointerEvents: isClickable ? 'auto' : 'none' }"
              @click="addline(item.typeName, item.fid)">
              <div class="name" style="font-size: 18px; font-weight: bold">
                {{ item.name }}
              </div>
              <div class="container">
                <img src="https://walkway.ntscn.com:8818/images/icon_attractions.png"
                  style="border: none;height: 14px;width: 14px;padding-right: 5px;"></img>
                景点：{{ item.scenes }}
                <div style="margin-left: 9px"></div>
                <div class="container2">
                  <img src="https://walkway.ntscn.com:8818/images/icon_length.png"
                    style=" border: none;height: 14px;  width: 14px; padding-right: 5px; "></img>

                  长度：{{ item.length }}米

                  <img src="https://walkway.ntscn.com:8818/images/icon_time.png"
                    style="border: none;height: 14px;width: 14px;padding-right: 5px; padding-left: 15px; "></img>

                  耗时：{{ item.time }}

                </div>
              </div>
            </div>
          </div>
        </div>
        <div :style="{ width: divWidth + 'vw' }" v-if="!isHave" class="noWalk">该步道暂无推荐线路</div>
      </div>
    </div>
    <!-- 我的位置和全部步道按钮 -->
    <div class="myposition">
      <img class="local" @click="findme()" src="https://walkway.ntscn.com:8818/images/positioning.svg" />
    </div>
    <div class="showWalkpoint" v-if="showEarth">
      <p style="color:black;position: absolute;margin-top:16%;font-size: 14px;margin-left: 3%;" @click="walkpoint()">返回
      </p>
      <!-- <img class="local" @click="walkpoint()"
        src="https://walkway.ntscn.com:8818/images/cf0aa3602dd3448996fe26e1d124144c.jpg" /> -->
    </div>
    <!-- 求助和步道介绍 -->
    <div class="help" @click="helpme()">
      <img class="helpimg" src="https://walkway.ntscn.com:8818/images/58473b09be734768b7ddbc150399cf7f.png" />
      <p id='JiuYuan' class="JiuYuan">
        一键救援
      </p>
    </div>
    <div class="trailInformation" @click="showInformation()">
      <img style=" width: 27px; height: 27px;top: 12%;left: 16%;position: absolute;"
        src="https://walkway.ntscn.com:8818/images/inf.png" />
      <p id='BuDao' class="JieShao">
        步道介绍 </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-wfst/dist/leaflet-wfst.src";
import city from "./city_id.json";
import axios from "axios";
import "leaflet.animatedmarker/src/AnimatedMarker";
import {
  getArticleListReq,
  getwalkway,
  getroad,
  getEarlyWarning,
  getArticleListReq_other
} from "../api/showpoint.js";
const baseLayer2 = L.tileLayer(
  "https://webrd0{s}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}",
  {
    attribution: "&copy; 高德地图",
    maxZoom: 20,
    minZoom: 4,
    subdomains: "1234",
    zoom: 3,
  }
);
const baseLayer = L.tileLayer(
  "https://webst01.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
  {
    attribution: "&copy; 高德地图",
    maxZoom: 20,
    minZoom: 4,
    subdomains: "1234",
    zoom: 3,
  }
);
const roadNetLayer = L.tileLayer(
  "https://webst01.is.autonavi.com/appmaptile?style=8&x={x}&y={y}&z={z}",
  {
    attribution: "&copy; 高德地图",
    maxZoom: 20,
    minZoom: 4,
  }
);

const maypostion = L.icon({
  iconUrl: "https://walkway.ntscn.com:8818/images/me.svg",
  iconSize: [20, 20],
  iconAnchor: [10, 10],
});
//步道概览点
const Overviewpoint = L.icon({
  iconUrl: "https://walkway.ntscn.com:8818/images/point.svg",
  iconSize: [20, 20],
  iconAnchor: [10, 14],
});
//步道上的数据点
const Datapoint = L.icon({
  iconUrl: "https://walkway.ntscn.com:8818/images/allway.svg",
  iconSize: [21, 21],
  iconAnchor: [5, 7],
});

let map = null;
let emptyLayer = new L.FeatureGroup();
let pointshowid = null;
let markerArr = [];
let wmsLayer = null;
const markers = [];
let allwalkLayer = null;
let pointmarker = null; // 各点添加点标记循环内的marker
export default {
  name: "HomeView",
  data() {
    return {
      isHave: true,
      divWidth: 100,
      showAllDiv: true,
      detailedCity: "",
      notice: "",
      noticeUrl: "",
      cityId: "",
      unit: "米",
      unit1: "米",
      geoJsonLayer: null,
      animationInterval: null,
      way: null,
      animatedMarker: null,
      features: [],
      isClickable: true,
      // 轨迹绘制的定时器（如果需要动态更新）
      whichmap: 1,
      drawer: false,
      showallway: false,
      showAbout: false, // 初始时关闭showabout
      layer1: null,
      height: "20%",
      response: null,
      picname: "null",
      picsrc: null,
      detailedCity: null,
      showlines: false,
      showEarth: true,
      distance: null,
      latitude: null,
      longitude: null,
      mylat: null,
      mylng: null,
      walkmarker: null,
      walklist: [],
      linelist: [],
      src_true: null,
      Datapoint_imgUrl: null,
      pointmarkers: [],//存储所有打点的数组
      clickedMarker: null,//存储点击点的索引
      pointType: [
        {
          image: "https://walkway.ntscn.com:8818/images/view1.png",
          id: 1,
          title: "观景台",
          description: 'plat'
        },
        {
          image: "https://walkway.ntscn.com:8818/images/Exit1.png",
          id: 5,
          title: "出入口",
          description: 'exit'
        },
        {
          image: "https://walkway.ntscn.com:8818/images/toliet1.png",
          id: 3,
          title: "卫生间",
          description: 'toliet'
        },
        {
          image: "https://walkway.ntscn.com:8818/images/village1.png",
          id: 0,
          title: "主峰",
          description: 'mount'
        },
        {
          image: "https://walkway.ntscn.com:8818/images/camp1.png",
          id: 4,
          title: "营地",
          description: 'camp'
        },
        {
          image: "https://walkway.ntscn.com:8818/images/rest1.png",
          id: 2,
          title: "驿站",
          description: 'relay'
        },
        {
          image: "https://walkway.ntscn.com:8818/images/lamp1.png",
          id: 6,
          title: "救援点",
          description: 'lamp'
        },
        {
          image: "https://walkway.ntscn.com:8818/images/park1.png",
          id: 7,
          title: "停车场",
          description: 'park'
        },
      ],
      pointType2: [
        {
          image: "https://walkway.ntscn.com:8818/images/view2.png",
          id: 1,
          title: "观景台",
          description: 'plat'
        },
        {
          image: "https://walkway.ntscn.com:8818/images/Exit2.png",
          id: 5,
          title: "出入口",
          description: 'exit'
        },
        {
          image: "https://walkway.ntscn.com:8818/images/toliet2.png",
          id: 3,
          title: "卫生间",
          description: 'toliet'
        },
        {
          image: "https://walkway.ntscn.com:8818/images/village2.png",
          id: 0,
          title: "主峰",
          description: 'mount'
        },
        {
          image: "https://walkway.ntscn.com:8818/images/camp2.png",
          id: 4,
          title: "营地",
          description: 'camp'
        },
        {
          image: "https://walkway.ntscn.com:8818/images/rest2.png",
          id: 2,
          title: "驿站",
          description: 'relay'
        },
        {
          image: "https://walkway.ntscn.com:8818/images/lamp2.png",
          id: 6,
          title: "救援点",
          description: 'lamp'
        },
        {
          image: "https://walkway.ntscn.com:8818/images/park2.png",
          id: 7,
          title: "停车场",
          description: 'park'
        },
      ],
      pointIcon: [
        "https://walkway.ntscn.com:8818/images/village1.png",
        "https://walkway.ntscn.com:8818/images/view1.png",
        "https://walkway.ntscn.com:8818/images/rest1.png",
        "https://walkway.ntscn.com:8818/images/toliet1.png",
        "https://walkway.ntscn.com:8818/images/camp1.png",
        "https://walkway.ntscn.com:8818/images/Exit1.png",
        "https://walkway.ntscn.com:8818/images/lamp1.png",
        "https://walkway.ntscn.com:8818/images/park1.png"
      ],
      pointIcon2: [
        "https://walkway.ntscn.com:8818/images/village2.png",
        "https://walkway.ntscn.com:8818/images/view2.png",
        "https://walkway.ntscn.com:8818/images/rest2.png",
        "https://walkway.ntscn.com:8818/images/toliet2.png",
        "https://walkway.ntscn.com:8818/images/camp2.png",
        "https://walkway.ntscn.com:8818/images/Exit2.png",
        "https://walkway.ntscn.com:8818/images/lamp2.png",
        "https://walkway.ntscn.com:8818/images/park2.png"
      ],
      pointType_true: [],
      pointIcon_true: [],
      isHavePoint: true,
      params1: {},
      walkway: {
        // 当前步道
        id: 0,
        fid: 0,
        name: null,
        pic: null,
        logo: null,
        long: null,
        currentRunner: null,
        distance: null,
        wmslayer: null,
      },
    };
  },
  mounted() {
    //获取传入的信息
    this.getLngLatFromQuery();
    //地图初始化
    this.initMap();
    window.test = this.test();
    this.pointType_true = this.pointType;
    this.pointIcon_true = this.pointIcon;
  },
  methods: {
    // 接收小程序传入数据的函数
    async getLngLatFromQuery() {
      const paramsStr = new URLSearchParams(window.location.search).get(
        "params"
      );
      if (paramsStr != null) {
        const that = this;
        const paramsObj = JSON.parse(paramsStr);
        console.log("json1:", paramsObj);
        that.mylng = paramsObj.lng;
        that.mylat = paramsObj.lat;
        that.detailedCity = paramsObj.detailedCity;
        that.cityId = paramsObj.cityId;
        // 调用查询城市的接口
        that.getEarlyWarning(that.detailedCity);
        if (paramsObj.way) {
          that.way = paramsObj.way;
          that.showEarth = false;
        }
      } else {
        that.$message("定位失败！默认您在北京");
        that.mylng = 116.2317;
        that.mylat = 39.5427;
      }
    },
    // 获取警报信息
    async getEarlyWarning(theCity) {
      var response = await getEarlyWarning({
        city: theCity,
      });
      console.log("earlyWarning", response);
      this.notice = response.data;
      this.noticeUrl =
        "/pages/notice/notice?cityId=" +
        this.cityId +
        "&earlyWarning=" +
        this.notice +
        "&detailedCity=" +
        this.detailedCity;
    },
    //底图矢量地图
    showdt() {
      const that = this;
      if (that.whichmap !== 1) {
        baseLayer.addTo(map);
        map.removeLayer(baseLayer2);
        baseLayer.bringToBack();
        that.whichmap = 1;
        const element1 = document.getElementById("changedt");
        // 设置新的方向
        element1.classList.remove("changedt1");
        const element2 = document.getElementById("changewx");
        // 设置新的方向
        element2.classList.remove("changewx1");
        let element3 = document.getElementById('JiuYuan')
        element3.classList.remove('test2')
        let element4 = document.getElementById('BuDao')
        element4.classList.remove('test2')
        let element5 = document.getElementById('ChaKan')
        element5.classList.remove('test2')
        this.pointType_true = this.pointType
        this.pointIcon_true = this.pointIcon
        Datapoint.options.iconUrl = this.pointIcon_true[this.Datapoint_imgUrl]
        this.src_true = this.pointIcon_true[this.Datapoint_imgUrl]
        this.updateMarkerIcon(this.pointIcon_true[this.Datapoint_imgUrl])
      }
    },
    // 底图卫星地图
    showwx() {
      const that = this;
      if (that.whichmap !== 2) {
        baseLayer2.addTo(map);
        map.removeLayer(baseLayer);
        baseLayer2.bringToBack();
        that.whichmap = 2;
        const element1 = document.getElementById("changedt");
        // 设置新的方向
        element1.classList.add("changedt1");
        // console.log('b11')
        const element2 = document.getElementById("changewx");
        // 设置新的方向
        element2.classList.add("changewx1");
        let element3 = document.getElementById('JiuYuan')
        element3.classList.add('test2')
        let element4 = document.getElementById('BuDao')
        element4.classList.add('test2')
        let element5 = document.getElementById('ChaKan')
        element5.classList.add('test2')
        this.pointType_true = this.pointType2
        this.pointIcon_true = this.pointIcon2
        Datapoint.options.iconUrl = this.pointIcon_true[this.Datapoint_imgUrl]
        this.src_true = this.pointIcon_true[this.Datapoint_imgUrl]
        this.updateMarkerIcon(this.pointIcon_true[this.Datapoint_imgUrl])
      }
    },

    showabout() {
      const that = this;
      that.showlines = false;
    },
    //更改打点的图标
    updateMarkerIcon(newIconUrl) {
      const newIcon = L.icon({
        iconUrl: newIconUrl,
      });
      this.pointmarkers.forEach((marker) => {
        if (marker !== this.clickedMarker) { // 跳过被点击的标记
          marker.setIcon(newIcon);
        }
      });
    },
    //画推荐线路
    addline(a, b) {
      const that = this;
      const c = "FID='".concat(b).concat("'");
      if (b === null) {
        that.$message("暂无图层数据");
      } else {
        that.showline(a, c);
      }
    },
    // 推荐线路请求
    async getroad() {
      const that = this;
      const data = null;
      const params4 = {
        walkwayId: that.walkway.fid,
        pageNum: 1,
        pageSize: 1000,
      };
      const response1 = await getroad(data, params4);
      const response2 = response1.data.result.records;
      that.linelist = response2;
      console.log("that.linelist", that.linelist, that.linelist.length);
      if (that.linelist.length === 0) {
        that.isHave = false;
        that.divWidth = 100;
        console.log("有无推荐线路", this.isHave);
      } else {
        that.isHave = true;
        that.$nextTick(() => {
          that.divWidth = that.linelist.length * 90;
          console.log(that.divWidth);
        });
      }

    },
    // 显示点的函数
    showpoint(e, imgUrl, description) {
      console.log(e, "显示点");
      if (pointshowid === e && markerArr.length !== 0) {
        emptyLayer.clearLayers();
        emptyLayer = new L.FeatureGroup();
        pointshowid = null;
      } else {
        const that = this;
        if (that.walkway.fid == 1 || that.walkway.fid == 2 || that.walkway.fid == 3 || that.walkway.fid == 4) {
          this.params1 = {
            symbolId: e,
            walkwayId: that.walkway.fid,
            longitude: that.mylng,
            latitude: that.mylat,
          };
        } else {
          this.params1 = {
            description: description,
            pointTableName: that.walkway.pointTableName,
            longitude: that.mylng,
            latitude: that.mylat,
          };
        }
        console.log(this.isHavePoint)

        this.getArticleListReq(e, this.params1,imgUrl);
        pointshowid = e;
        console.log(this.src_true)
        console.log(Datapoint.options.iconUrl)
        console.log(this.Datapoint_imgUrl)
      }
    },
    //点击上拉按钮后做改变的函数
    drawers() {
      const that = this;
      if (that.drawer === false) {
        that.drawer = true;
        that.showallway = true;
        const element = document.querySelector(".showwalk");
        // 设置新的高度值
        element.style.height = "42%";
        const element2 = document.querySelector(".showbutton");
        // 设置新的方向
        element2.classList.add("showbutton2");
      } else {
        that.drawer = false;
        that.showallway = false;
        const element = document.querySelector(".showwalk");
        // 设置新的高度值
        element.style.height = "20%";
        const element2 = document.querySelector(".showbutton");
        // 设置新的方向
        element2.classList.remove("showbutton2");
      }
    },
    // 加载wms
    addwms() {
      const that = this;
      if (wmsLayer !== null) {
        map.removeLayer(wmsLayer);
      }
      wmsLayer = L.tileLayer.wms(
        "https://walkway.ntscn.com/geoserver/cite/wms?",
        {
          layers: that.walkway.wmslayer, // 需要加载的图层
          format: "image/png", // 返回的数据格式
          transparent: true,
          zindex: 999,
        }
      );
      wmsLayer.addTo(map);
      that.getroad();
    },
    // 地图初始化
    initMap() {
      map = L.map("map", {
        minZoom: 3, // 最小缩放值
        maxZoom: 20, // 最大缩放值
        center: L.latLng(27.47921042, 120.45443452), // 注意和其他地图经纬度格式区别
        zoom: 14, // 初始缩放值
        layers: [baseLayer, roadNetLayer],
        zoomControl: true, // 是否启用地图缩放控件
        fadeAnimation: false,
        attributionControl: false, // 是否启用地图属性控件
        closePopupOnClick: false,
      });
      const that = this;
      const params2 = {
        longitude: that.mylng,
        latitude: that.mylat,
      };
      that.getwalkway(params2, 0);
      L.marker([that.mylat, that.mylng], {
        icon: maypostion,
      }).addTo(map);
    },

    // 显示概览点
    walkpoint() {
      const that = this;
      that.showlines = false;
      let n = 0;
      if (markerArr.length !== 0) {
        map.removeLayer(emptyLayer);
      }
      if (that.layer1 !== null) {
        map.removeLayer(that.layer1);
        if (this.geoJsonLayer) {
          map.removeLayer(this.geoJsonLayer);
          map.removeLayer(this.animatedMarker);
        }
      }
      if (wmsLayer) {
        map.removeLayer(wmsLayer);
      }

      wmsLayer = null;
      console.log("that.walklist", that.walklist);
      while (n < that.walklist.length) {
        // console.log(n,"步道图标:",that.walklist[n].logo);
        const x = that.walklist[n].walkwayLatitude;
        const y = that.walklist[n].walkwayLongitude;
        Overviewpoint.options.iconUrl = that.walklist[n].logo;
        // console.log("Overviewpoint.options.iconUrl",Overviewpoint.options.iconUrl);
        // console.log("Overviewpoint",Overviewpoint);
        // that.walkmarker = L.marker([x, y], {
        //   icon: Overviewpoint,
        //   data: that.walklist[n],
        // });
        that.walkmarker = L.marker([x, y], {
          icon: L.icon({
            iconUrl: that.walklist[n].logo,
            iconSize: [30, 30],
            iconAnchor: [10, 14],
          }),
          data: that.walklist[n],
        });
        console.log("that.walkmarker", that.walkmarker);
        markers.push(that.walkmarker);
        that.walkmarker.on("click", function (e) {
          const clickwalk = e.target.options.data;
          that.walkway.name = clickwalk.walkwayName;
          that.walkway.logo = clickwalk.logo;
          that.walkway.pic = clickwalk.headPhoto;
          that.walkway.long = clickwalk.totalLength;
          that.walkway.distance = clickwalk.distance;
          that.walkway.currentRunner = clickwalk.currentRunner;
          that.walkway.wmslayer = clickwalk.layers;
          that.walkway.fid = clickwalk.id;
          that.walkway.pointTableName = clickwalk.pointTableName;
          map.setView(
            [clickwalk.walkwayLatitude, clickwalk.walkwayLongitude],
            clickwalk.mapScale
          );
          that.addwms();
          map.removeLayer(allwalkLayer);
        });
        n++;
      }
      map.setView([30.4625159, 114.6145455], 4);
      allwalkLayer = L.layerGroup(markers);
      allwalkLayer.addTo(map);
    },

    test() { },
    // 请求点数据
    async getArticleListReq(e, params1,imgUrl) {
      const data = null;
      let n = 0;
      if (params1.hasOwnProperty('description')) {
        const response1 = await getArticleListReq_other(data, params1);
        this.response = response1.data.result;
      } else {
        const response1 = await getArticleListReq(data, params1);
        this.response = response1.data.result;
      }
      console.log("刚刚ArticleListReq", this.response);
      if (this.response != null) {
        Datapoint.options.iconUrl = this.pointIcon_true[e];
        this.Datapoint_imgUrl = e;
        this.src_true = imgUrl
        const that = this;
        that.isHavePoint = true
        // 添加点标记
        // 清除点
        if (markerArr.length !== 0) {
          // console.log('11234')
          map.removeLayer(emptyLayer);
          markerArr = [];
          emptyLayer.clearLayers();
        }
        while (n < that.response.length) {
          markerArr.push(that.response[n]);
          n++;
        }
        markerArr.forEach((item) => {
          pointmarker = L.marker([item.latitude, item.longitude], {
            icon: Datapoint,
            data: item,
          });
          // 点的点击事件各个
          if (that.isHavePoint) {
            pointmarker.on("click", function (e) {
              that.clickedMarker = e.target;//存储点击点的索引用于后面切换地图时不被更换图标
              emptyLayer.eachLayer(function (layer) {
                const newIcon = L.icon({
                  // 图标地址
                  iconUrl: "https://walkway.ntscn.com:8818/images/point.svg",
                  // 图标大小
                  iconSize: [23, 30],
                });
                layer.setIcon(Datapoint); // 旧图标
                if (layer.options.data.fid === e.target.options.data.fid) {
                  // 通过title找到当前点击的图标
                  layer.setIcon(newIcon); // 替换为新图标
                }
              });
              that.showAbout = true;
              const data = e.target.options.data;
              that.picname = data.name;
              that.picsrc = "https://walkway.ntscn.com:8818/images/cangnan.jpg";
              that.distance = data.distance;
              if (that.distance > 1000) {
                that.unit1 = "公里";
                that.distance = parseFloat(that.distance / 1000).toFixed(2);
              }
              that.latitude = data.latitude;
              that.longitude = data.longitude;
            });
          }
          pointmarker.addTo(emptyLayer);
          that.pointmarkers.push(pointmarker)
        });
        emptyLayer.addTo(map);
        this.isHavePoint = true;
      } else {
        this.$message("无数据1");
        this.isHavePoint = false;
      }
    },
    closeAbout() {
      this.showAbout = false; // 关闭 showabout
    },
    // 我的定位
    findme() {
      map.setView([this.mylat, this.mylng], 12);
    },
    // 画线路
    showline(typeName, filter) {
      const that = this;
      const params = {
        service: "WFS",
        version: "1.1.0",
        typeNS: "cite",
        request: "GetFeature",
        typeName: typeName,
        cql_filter: filter,
        outputFormat: "application/json",
        srsName: "EPSG:4326",
      };
      axios({
        url: "https://walkway.ntscn.com/geoserver/cite/ows?",
        params: params,
      }).then((res) => {
        // 拿到数据之后，你要执行的操作
        if (that.layer1 !== null) {
          map.removeLayer(that.layer1);
          if (this.geoJsonLayer) {
            map.removeLayer(this.geoJsonLayer);
            map.removeLayer(this.animatedMarker);
          }
        }
        this.processGeoJsonData(JSON.parse(res.request.response));
        that.layer1 = L.geoJson(res.data);
      });
    },
    processGeoJsonData(geoJsonData) {
      const features = geoJsonData.features;
      if (features) {
        features.forEach((feature) => {
          this.animatePath(feature);
        });
      }
    },
    // 动态路径
    animatePath(feature) {
      map.setView;
      const carIcon = L.icon({
        iconUrl:
          "https://walkway.ntscn.com:8818/images/b7752545fcc84f5f83c6aa424b3e98c7.png", //图片
        iconSize: [35, 30], //尺寸
        iconAnchor: [30, 30],
        opacity: 1,
        popupAnchor: [10, 10],
      });
      const path = feature.geometry.coordinates[0].map(([x, y]) => [y, x]);
      map.setView(path[0], 15);
      const polylineOptions = {
        color: "rgb(20, 255, 255)",
        weight: 5,
        opacity: 1,
        smoothFactor: 1,
      };
      // 创建并添加折线图层g
      if (this.geoJsonLayer) {
        map.removeLayer(this.geoJsonLayer);
        map.removeLayer(this.animatedMarker);
      }
      this.geoJsonLayer = L.polyline([], polylineOptions).addTo(map);
      var seed = 10 / path.length;
      this.isClickable = false;
      this.animatedMarker = L.animatedMarker(path, {
        autoStart: true, // 是否自动开始动画
        icon: carIcon, // 使用自定义图标
        interval: seed,
        onEnd: () => {
          console.log("移动结束");
          this.isClickable = true;
          this.layer1.addTo(map);
        },
      }).addTo(map);
      this.animatedMarker.on("move", (e) => {
        this.geoJsonLayer.addLatLng([e.latlng.lat, e.latlng.lng]);
        map.panTo([e.latlng.lat, e.latlng.lng]);
      });
    },
    //点击跳转到步道介绍页面
    showInformation() {
      console.log(window, "window");
      if (window.hasOwnProperty("wx")) {
        console.log("wx is attached to the current window object.");
      } else if (parent && parent.hasOwnProperty("wx")) {
        console.log("wx is attached to the parent object.");
      } else {
        console.log(
          "wx is not attached to either the current window or parent object."
        );
      }
      let webViewUrl1 = null;
      for (var a = 0; a < this.walklist.length; a++) {
        if (this.walklist[a].id == this.walkway.fid) {
          console.log("webViewUrl", this.walklist[a]);
          webViewUrl1 =
            "/pages/find2/roadIntroduction?item=" +
            encodeURIComponent(JSON.stringify(this.walklist[a]));
        }
      }
      wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {
          this.isMiniProgram = true;
        }
      });
      console.log("webViewUrl1", webViewUrl1);
      if (webViewUrl1 === null) {
        console.log('娇神很强')
        wx.miniProgram.navigateTo({
          appId: "wxb689af7a76f320d8",
          url: '/pages/find2/roadAllIntroduction',
          fail: function (err) {
            wx.miniProgram.navigateTo({ url: webViewUrl1 });
          },
        });
      } else {
        wx.miniProgram.navigateTo({
          appId: "wxb689af7a76f320d8",
          url: webViewUrl1,
          fail: function (err) {
            wx.miniProgram.navigateTo({ url: webViewUrl1 });
          },
        });
      }
      // wx.miniProgram.navigateTo({
      //   appId: "wxb689af7a76f320d8",
      //   url: webViewUrl1,
      //   fail: function (err) {
      //     wx.miniProgram.navigateTo({ url: webViewUrl1 });
      //   },
      // });
    },
    //点击跳转到帮助页面
    helpme() {
      if (window.hasOwnProperty("wx")) {
        console.log("wx is attached to the current window object.");
      } else if (parent && parent.hasOwnProperty("wx")) {
        console.log("wx is attached to the parent object.");
      } else {
        console.log(
          "wx is not attached to either the current window or parent object."
        );
      }
      const webViewUrl1 = "/pages/help/help";
      wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {
          this.isMiniProgram = true;
        }
      });
      wx.miniProgram.navigateTo({
        appId: "wxb689af7a76f320d8",
        url: webViewUrl1,
        fail: function (err) {
          wx.miniProgram.navigateTo({ url: webViewUrl1 });
        },
      });
    },
    //点击跳转到预警页面
    showNotice() {
      if (window.hasOwnProperty("wx")) {
        console.log("wx is attached to the current window object.");
      } else if (parent && parent.hasOwnProperty("wx")) {
        console.log("wx is attached to the parent object.");
      } else {
        console.log(
          "wx is not attached to either the current window or parent object."
        );
      }
      console.log(1111, this.noticeUrl);
      wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {
          this.isMiniProgram = true;
        }
      });
      wx.miniProgram.navigateTo({
        appId: "wxb689af7a76f320d8",
        url: this.noticeUrl,
        // success:function(res){console.log("success",res) },
        fail: function (err) {
          //  console.log(err)
          wx.miniProgram.navigateTo({ url: this.noticeUrl });
        },
      });
    },
    //点击跳转到导航页面
    open(latitude, longitude, picname) {
      const webViewUrl =
        "/pages/qqmap/test?latitude=" +
        latitude +
        "&longitude=" +
        longitude +
        "&name=" +
        picname;
      wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {
          this.isMiniProgram = true;
        }
      });
      wx.miniProgram.navigateTo({ url: webViewUrl });
    },
    //添加一条线路
    add1(e) {
      const that = this;
      that.walkway.id = e;
      that.walkway.name = that.walklist[that.walkway.id].walkwayName;
      that.walkway.pic = that.walklist[that.walkway.id].headPhoto;
      that.walkway.long = that.walklist[that.walkway.id].totalLength;
      that.walkway.distance = that.walklist[that.walkway.id].distance;
      that.walkway.currentRunner = that.walklist[that.walkway.id].currentRunner;
      that.walkway.wmslayer = that.walklist[that.walkway.id].layers;
      that.walkway.fid = that.walklist[that.walkway.id].id;
      that.walkway.logo = that.walklist[that.walkway.id].logo;
      map.setView(
        [
          that.walklist[that.walkway.id].walkwayLatitude,
          that.walklist[that.walkway.id].walkwayLongitude,
        ],
        that.walklist[that.walkway.id].mapScale
      );
      that.addwms();
    },
    //按距离获取所有步道
    async getwalkway(params2, e) {
      const that = this;
      const data = null;
      const response2 = await getwalkway(data, params2);
      that.walklist = response2.data.result;
      that.walklist.forEach((item) => {
        if (item.distance > 1000) {
          that.unit = "公里";
          item.distance = parseFloat(item.distance / 1000).toFixed(2);
        }
        if (item.totalLength > 1000) {
          that.unit = "公里";
          item.totalLength = parseFloat(item.totalLength / 1000).toFixed(2);
        }
      });
      if (that.way == null) {
        that.walkpoint();
      } else {
        console.log(that.walklist);
        for (var i = 0; i < that.walklist.length; i++) {
          if (that.walklist[i].id == that.way) {
            e = i;
            that.add1(e);
            break;
          }
        }
      }
    },
    showall(e) {
      const that = this;
      if (markerArr.length !== 0) {
        map.removeLayer(emptyLayer);
      }
      if (that.layer1 !== null) {
        map.removeLayer(that.layer1);
        if (this.geoJsonLayer) {
          map.removeLayer(this.geoJsonLayer);
          map.removeLayer(this.animatedMarker);
        }
      }
      that.add1(e);
    },
  },
  beforeDestroy() {
    // 清除动画定时器
    if (this.animationInterval) {
      clearInterval(this.animationInterval);
    }
    // 移除地图
    if (this.map) {
      this.map.remove();
    }
  },
};
</script>
<style lang="scss">
#map {
  height: 100%;
  width: 100%;
}

.leaflet-touch .leaflet-bar a:first-child {
  right: 0px;
  position: absolute;
}

.leaflet-touch .leaflet-bar a:last-child {
  right: 0px;
  bottom: 0px;
  position: absolute;
}

.leaflet-touch .leaflet-bar {
  position: fixed;
  top: 16%;
  right: 2.8%;
  background-color: #ffffff !important;
  border: 1px solid #d7d7d7;
}

.pointlist {
  background-color: white;
  position: absolute;
  z-index: 998;
  font-size: 14px;
  top: 18%;
  border-radius: 10px;
}

.showmune {
  background-color: white;
  position: absolute;
  z-index: 998;
  top: 40%;
  font-size: 13px;
  border-radius: 4px;
}

.map {
  height: 95%;
  top: 5%;
  width: 100%;
  position: absolute;
  z-index: 950;
}

.map1 {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 900;
}

.help {
  /* display: flex; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  white-space: nowrap;
  z-index: 997;
  width: 2.5rem;
  height: 40px;
  position: absolute;
  top: 7%;
  left: 3.5%;
  border-radius: 22px;
  background-color: rgb(255, 255, 255);
}

.trailInformation {
  z-index: 997;
  width: 40px;
  height: 40px;
  position: absolute;
  white-space: nowrap;
  top: 17%;
  left: 3.5%;
  border-radius: 22px;
  background-color: rgb(255, 255, 255);
}

.myposition {
  z-index: 997;
  width: 8%;
  height: 4.8%;
  position: absolute;
  top: 23%;
  right: 2.9%;
  background-color: rgb(255, 255, 255);
}

.helpimg {
  width: 29px;
  height: 29px;
  top: 10%;
  left: 15%;
  position: absolute;
}

.local {
  width: 23px;
  height: 23px;
  top: 13%;
  left: 10%;
  position: absolute;
}

.showWalkpoint {
  z-index: 997;
  width: 8%;
  height: 4.8%;
  position: absolute;
  top: 28%;
  right: 2.9%;
  background-color: rgb(255, 255, 255);
  white-space: nowrap;
}

#showwalk {
  z-index: 997;
  height: 20%;
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  bottom: 0%;
  left: 0px;
  /* top: 15%; */
  border-color: rgba(255, 255, 255, 0.84);
  border-radius: 10px 10px 0px 0px;
  position: absolute;
}

#allbutton {
  z-index: 999;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.84);
  height: 18px;
  left: 46%;
  top: -17.91px;
  border-color: rgba(255, 255, 255, 0.84);
  border-radius: 90% 90% 0 0;
  width: 8%;
}

#walkdata {
  z-index: 998;
  height: 128px;
  background-color: rgba(255, 255, 255, 0.84);
  width: 100%;
  position: absolute;
  left: 0%;
  /* border-bottom: rgba(255, 255, 255, 0.84) solid 1px; */
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid #f2f2f2;
  /* border-bottom: 1px solid #000; */
}

#walkpic {
  z-index: 999;
  height: 68%;
  background-color: rgba(255, 255, 255, 0);
  width: 23%;
  top: 18%;
  left: 4%;
  border-radius: 8px;
  position: absolute;
}

#walkname {
  background-color: rgb(255, 255, 255, 0);
  color: #000;
  text-align: left;
  z-index: 999;
  height: 10%;
  width: 65%;
  top: 15%;
  left: 32%;
  position: absolute;
  font-size: 16px;
  font-weight: 500;
}

#walkdistance {
  background-color: rgb(255, 255, 255, 0);
  color: #000;
  z-index: 999;
  height: 15%;
  width: 28%;
  top: 44%;
  text-align: left;
  left: 32%;
  position: absolute;
  font-size: 13px;
  font-weight: 450;
}

#walklong {
  background-color: rgb(255, 255, 255, 0);
  color: #000;
  z-index: 999;
  height: 15%;
  width: 30%;
  top: 67%;
  text-align: left;
  left: 32%;
  position: absolute;
  font-size: 13px;
  font-weight: 450;
}

#walkcurrent {
  background-color: rgb(255, 255, 255, 0);
  color: #000;
  z-index: 999;
  height: 15%;
  width: 30%;
  top: 44%;
  text-align: left;
  left: 65%;
  position: absolute;
  font-size: 13px;
  font-weight: 450;
}

.list-item {
  color: #000;
  border: 0px;
  margin-left: auto;
  flex-basis: 130%;
  font-size: 13px;
  height: 100%;
  width: 100%;
  top: 0%;
}

.linelist-item {
  background-color: rgb(243, 243, 243);
  color: #000;
  z-index: 999;
  margin-left: auto;
  flex-basis: 720px;
  font-size: 13px;
  height: 120px;
  width: 100%;
}

#smallpic {
  height: 48px;
  width: 48px;
  top: 14%;
  z-index: 999;
  border-radius: 50%;
  position: relative;
}

.pointimg {
  height: 55%;
  width: 55%;
}

.showabout {
  z-index: 999;
  height: 20%;
  background-color: rgb(255, 255, 255);
  width: 100%;
  bottom: 0%;
  position: absolute;
  /* opacity: 1; */
  box-shadow: 0px -2px 0px 0px rgb(218, 217, 217);
  border-radius: 10px 10px 0px 0px;
}

#name {
  background-color: rgb(255, 255, 255);
  color: #0f0e0e;
  text-align: left;
  z-index: 999;
  height: 10%;
  width: 60%;
  top: 15%;
  left: 35%;
  position: absolute;
  font-size: 19px;
  font-weight: 560;
}

#pic {
  z-index: 999;
  height: 68%;
  width: 23%;
  top: 18%;
  left: 4%;
  border-radius: 8px;
  position: absolute;
}

.order {
  font-size: 22px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 545;
  height: 40px;
}

#distance {
  background-color: rgb(255, 255, 255);
  color: #0f0e0e;
  z-index: 999;
  height: 15%;
  width: 60%;
  top: 45%;
  text-align: left;
  left: 35%;
  position: absolute;
  font-size: 14px;
  font-weight: 450;
}

.showbutton {
  transform-origin: center center;
}

.showbutton2 {
  transform-origin: center center;
  transform: rotate(180deg);
}

#go {
  background-color: rgb(255, 255, 255);
  color: #0f0e0e;
  z-index: 999;
  height: 25%;
  width: 30%;
  top: 65%;
  left: 68%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 480;
}

.el-icon-close {
  color: #0f0e0e;
  z-index: 999;
  height: 15%;
  width: 5%;
  top: 5%;
  left: 93%;
  position: absolute;
  font-size: 18px;
  font-weight: 500;
}

.el-drawer__wrapper {
  bottom: 0%;
}

.headText2 {
  margin-top: 3px;
  top: 3%;
  margin-left: 42px;
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  position: absolute;
  z-index: 999;
  color: black;
  line-height: 24px;
}

.title {
  width: 45px;
  height: 94%;
  background-color: #3088fd;
  background: -webkit-linear-gradient(left, #338aff, #0d6ded);
  border-radius: 10px 0px 0px 10px;
  position: absolute;
  margin-top: 40px;
  left: 10px;
  z-index: 999;
  top: -35px;
}

.lines {
  background-color: rgb(243, 243, 243);
  z-index: 998;
  border-radius: 15px 15px 0px 0px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 40%;
}

.order2 {
  width: 100rpx;
  height: 240rpx;
  line-height: 100rpx;
  font-size: 22px;
  font-weight: bold;
  color: white;
  position: relative;
  top: 15px;
  left: 10px;
  width: 24px;
  letter-spacing: 0px;
}

.number {
  font-size: 22px;
  color: white;
  font-weight: bold;
  position: absolute;
  left: 15px;
  top: 77px;
  text-align: center;
}

.content {
  width: 94%;
  left: 2%;
  position: relative;
  height: 95%;
  background-color: #ffffffe1;
  border-radius: 15px 15px 15px 15px;
  margin-top: 1%;
}

.name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  position: absolute;
  top: 10px;
  text-align: left;
  left: 50px;
  background-color: #ffffff;
}

.listview {
  position: relative;
  height: 200px;
  top: -5px;
  overflow-y: scroll;
  overscroll-behavior: none;
}

.container {
  position: absolute;
  top: 40px;
  left: 60px;
  width: 250px;
  font-size: 14px;
  text-align: left;
  color: #7f7f7f;
  background-color: rgb(252, 252, 252);
}

.el-aside::-webkit-scrollbar {
  width: 0;
}

.showallwalk::-webkit-scrollbar {
  display: none;
}

.change {
  background-color: #6b6b6b;
  position: absolute;
  top: 2%;
  right: 3%;
  z-index: 999;
  height: 25px;
  width: 90px;
  white-space: nowrap
}

.grid-item {
  height: 60px;
  margin-top: 5px;
  text-align: center;
}

.changewx {
  background-color: #ffffff;
  width: 45px;
  height: 25px;
  position: absolute;
  right: 0px;
  color: #000000;
  top: 0%;
  padding-top: 5px;
}

.changewx1 {
  background-color: #0d6ded;
  width: 45px;
  height: 25px;
  position: absolute;
  right: 0px;
  color: #ffffff;
  top: 0%;
  padding-top: 5px;
}

.changedt {
  background-color: #0d6ded;
  width: 45px;
  height: 25px;
  position: absolute;
  left: 0px;
  top: 0%;
  color: #ffffff;
  padding-top: 5px;
}

.changedt1 {
  background-color: #ffffff;
  width: 45px;
  height: 25px;
  position: absolute;
  left: 0px;
  top: 0%;
  color: #000000;
  padding-top: 5px;
}

.my-tooltip {
  position: absolute;
  top: -12px;
  pointer-events: auto !important;
}

.leaflet-popup-close-button {
  display: none;
}

.leaflet-popup-content-wrapper {
  border-radius: 5px;
}

.leaflet-popup-tip-container {
  width: 26px !important;
  height: 15px !important;
  margin-left: -13px !important;
}

.leaflet-popup-tip {
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.4) !important;
}

.animated-marker-icon {
  background-color: red;
  color: white;
  border-radius: 50%;
  z-index: 999;
  text-align: center;
  line-height: 41px;
}

.allwalk {
  z-index: 997;
  height: 100%;
  background-color: rgb(255, 255, 255, 0.84);
  width: 180%;
  bottom: 0%;
  left: 0%;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  flex-wrap: nowrap;
  position: absolute;
}

.noWalk {
  height: 100%;
  background-color: rgb(255, 255, 255, 0.84);
  display: flex;
  justify-content: center;
  align-items: center;
}

.u-notice-bar {
  width: 100%;
  height: 5%;
  line-height: 30px;
  overflow: hidden;
  position: relative;
  background-color: #f5edcb;
  color: #e88c04;
  top: 0;
  z-index: 997;
}

.u-notice-bar-content {
  position: absolute;
  width: 90%;
  height: 100%;
  left: 10%;
  white-space: nowrap;
  animation: marquee 4s linear infinite;
}

.pointImgs {
  width: 32px;
  height: 32px;
  padding: 1%;
}

.u-notice-bar-text {
  display: inline-block;
  padding-left: 100%;
}

.JiuYuan {
  position: absolute;
  margin-top: 93%;
  font-size: 12px;
  width: 120%;
  margin-left: -18%;
  color: #fff
}

.JieShao {
  position: absolute;
  margin-top: 93%;
  font-size: 12px;
  width: 120%;
  margin-left: -16%;
  color: #fff
}

.ChaKan {
  position: absolute;
  color: white;
  width: 9rem;
  font-size: 14px;
  margin-top: -60%;
  margin-left: -190%
}

.test2 {
  color: black
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}
</style>

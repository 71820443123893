import axios from 'axios'
let $http = null
export const getArticleListReq = (data, params) => {
  $http = axios.create({
    // baseURL: 'https://walkway.ntscn.com:8818',
    baseURL: 'https://walkway.ntscn.com',
    timeout: 5000, // 超时时间
    // headers: {
    //   // 编译语言
    //   // 'Content-type': 'application/json;charset=utf-8'
    // },
    params: {}
  })
  return $http({
    method: 'GET',
    url: '/Walkway/point/distance',
    params: params,
    // headers: {
    //   'Content-Type': 'application/json;charset=UTF-8'
    // },
    data: data
  })
}
export const getArticleListReq_other = (data, params) => {
  $http = axios.create({
    // baseURL: 'https://walkway.ntscn.com:8818',
    // baseURL: 'https://walkway.ntscn.com',
    baseURL: 'https://walkway.ntscn.com:8188',
    timeout: 5000, // 超时时间
    // headers: {
    //   // 编译语言
    //   // 'Content-type': 'application/json;charset=utf-8'
    // },
    params: {}
  })
  return $http({
    method: 'GET',
    url: '/MapDealer/pointTable/distance',
    params: params,
    // headers: {
    //   'Content-Type': 'application/json;charset=UTF-8'
    // },
    data: data
  })
}
export const getEarlyWarning = (data) => {
  $http = axios.create({
    // baseURL: 'https://walkway.ntscn.com:8818',
    baseURL: 'https://walkway.ntscn.com',
    timeout: 5000, // 超时时间
    // headers: {
    //   // 编译语言
    //   // 'Content-type': 'application/json;charset=utf-8'
    // },
    params: {}
  })
	return $http({
		url: '/Walkway/weather/search',
		method: 'GET',
		data:data
	})
}
export const getwalkway = (data, params) => {
  $http = axios.create({
    // baseURL: 'https://walkway.ntscn.com:8818',
    baseURL: 'https://walkway.ntscn.com',
    timeout: 5000, // 超时时间
    // headers: {
    //   // 编译语言
    //   // 'Content-type': 'application/json;charset=utf-8'
    // },
    params: {}
  })
  return $http({
    method: 'GET',
    url: '/Walkway/backWalkway/distance',
    params: params,
    // headers: {
    //   'Content-Type': 'application/json;charset=UTF-8'
    // },
    data: data
  })
}
export const getroad = (data, params) => {
  $http = axios.create({
    // baseURL: 'https://walkway.ntscn.com:8818',
    baseURL: 'https://walkway.ntscn.com',
    timeout: 5000, // 超时时间
    // headers: {
    //   // 编译语言
    //   // 'Content-type': 'application/json;charset=utf-8'
    // },
    params: {}
  })
  return $http({
    method: 'GET',
    url: '/Walkway/route/selectByThings',
    params: params,
    // headers: {
    //   'Content-Type': 'application/json;charset=UTF-8'
    // },
    data: data
  })
}

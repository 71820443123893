import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// const QQMapWX = require('./utils/qqmap-wx-jssdk.min.js');
// // 配置key
// let qqmapsdk = new QQMapWX({
// 	key: 'B7YBZ-CK6WB-ZUFUP-JR6EU-QFVGS-KCBB3'
//  });
// //挂载到原型
// Vue.prototype.$QQmap = qqmapsdk;
Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  router,
  store,
  ElementUI,
  render: h => h(App)
}).$mount('#app')
